import React from 'react';
//Style CSS
import './TeamMain.css';
//Images
import Soliman from '../../img/team-marc.jpg';
import Agnes from '../../img/team-agnes.jpg';
import John from '../../img/team-john.jpg';
import Mario from '../../img/team-mario.png';
import Sherwin from '../../img/team-sherwin.png'
import Blank from '../../img/team-blank.jpg'

import { motion } from 'framer-motion';
import { titleAnimation } from '../../components/animation';

const HeadOffice = () => {
    return(
        <div className="container main-team">
            <motion.h1 variants={titleAnimation} className="main-team-title">Our Team</motion.h1>
            <motion.div variants={titleAnimation}>
                <h2 className="main-team-title-2">Head Office</h2>
                <div className="team-box-2">
                    <div className="team-1-cont">
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={Soliman} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">Marco Antonio Soliman</h3>
                                <span className="team-post">President / CEO</span>
                            </div>
                        </div>
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={Agnes} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">Agnes Deniega</h3>
                                <span className="team-post">General Manager</span>
                            </div>
                        </div>
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={John} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">John Abejuro</h3>
                                <span className="team-post">Research and Development</span>
                            </div>
                        </div>
                    </div>
                    <div className="team-2-cont">
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={Mario} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">Mario Paraiso</h3>
                                <span className="team-post">Regional Head</span>
                            </div>
                        </div>
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={Sherwin} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">Sherwin Fajardo</h3>
                                <span className="team-post">CPA Finance Head</span>
                            </div>
                        </div>
                        <div className="team-card">
                            <div className="team-avatar">
                                <img src={Blank} alt="avatar"/>
                            </div>
                            <div className="team-content">
                                <h3 className="team-title">...</h3>
                                <span className="team-post">...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default HeadOffice