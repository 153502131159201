import React from 'react';
//Style CSS
import './TeamMain.css';
//Images
import Cathy from '../../img/team-cathy.jpg';
import Blank from '../../img/team-blank.jpg'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const HRDept = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="container main-team">
            <h2 className="main-team-title-2">Human Resource Department</h2>
            <div className="team-box-2">
                <div className="team-1-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Cathy} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Cathy Car</h3>
                            <span className="team-post">Human Resource Head</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Janine Moya</h3>
                            <span className="team-post">HR Staff - Recruitment</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">Sarah Gem Opeña</h3>
                            <span className="team-post">HR Staff - Benefits</span>
                        </div>
                    </div>
                </div>
                <div className="team-2-cont">
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">...</h3>
                            <span className="team-post">...</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">...</h3>
                            <span className="team-post">...</span>
                        </div>
                    </div>
                    <div className="team-card">
                        <div className="team-avatar">
                            <img src={Blank} alt="avatar"/>
                        </div>
                        <div className="team-content">
                            <h3 className="team-title">...</h3>
                            <span className="team-post">...</span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default HRDept