import React from 'react';
//Style CSS
import './SecondFooter.css';

const SecondFooter = () => {
    const fbfoothandleClick = () => {
        window.open("https://www.facebook.com/mamsltg/?hc_ref=ARS7Zvhy22p9VHz3CguJwLun_Df5Z7YWrsav-j32x_HmLtk5FPQuEYIfGw8tdG7asUE&fref=nf&__tn__=kC-R");
      };

      const ytfoothandleClick = () => {
        window.open("https://www.youtube.com/channel/UCpxZqYEJOLeH0fTfbu0--SA");
      };
    return(
        <div className="secondfooter-main">
            <div className="secondfooter-cont">
                <div className="secondfooter-icon">
                    <i onClick={fbfoothandleClick} className="fab fa-facebook-f fb-icon"></i>
                    <i onClick={ytfoothandleClick} className="fab fa-youtube yt-icon"></i>
                </div>
                <p className="arr-text">LT&G Credit Line Corp.© 2021 All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default SecondFooter;