import React from 'react'
//React Bootstrap Components
import Carousel from 'react-bootstrap/Carousel'
//style css
import './CarouselCont.css'
//Images
import Image1 from '../../img/header-1.jpg'
import Image2 from '../../img/header-2.jpg'
import Image3 from '../../img/header-3.jpg'
import Image4 from '../../img/header-4.jpg'
import Image5 from '../../img/header-5.jpg'
//Link 
import { Link } from 'react-router-dom';


const CarouselCont = () => {
    return(
        <Carousel fade>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image5}
                alt="First slide"
                />
                <Carousel.Caption>
                <a className="register-btn" href="https://www.pfa.org.ph" target="_blank" rel="noopener noreferrer">Register Now!</a>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image4}
                alt="Second slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image1}
                alt="Third slide"
                />
                <Carousel.Caption>
                <Link className="franchise-btn" to="/contact">Franchise Now</Link>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image2}
                alt="Fourth slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={Image3}
                alt="Fifth slide"
                />
                <Carousel.Caption>
                <Link className="leave-message-btn" to="/contact">Leave a Message</Link>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default CarouselCont;