import React from 'react';
//Style CSS
import './Franchise3rd.css'

import { motion } from 'framer-motion';
import { fade } from '../../components/animation';
import { useScroll } from '../../components/useScroll';

const Franchise3rd = () => {
    const [element, controls] = useScroll();
    return(
        <motion.div variants={fade} animate={controls} initial="hidden" ref={element} className="container fr3rd-cont">
            <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button className="accordion-button fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        FRANCHISE FEE
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div className="accordion-body">
                        <h4 className="fr3rd-subtitle">PHP 448k (VAT Inclusive)</h4>
                            <ul>
                                <li>Use of LT&G Credit Line trademark and logos</li>
                                <li>Comprehensive Operations Manual</li>
                                <li>Hiring assistance</li>
                                <li>Research and Development Activities</li>
                                <li>Pre-Opening support</li>
                                <li>Continuing business guidance and support</li>
                            </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                        OTHER EXPENSES
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body">
                        <h4 className="fr3rd-subtitle">Cash Exposure For Sales / Other Payables To Third Party</h4>
                        <ul>
                            <li>Lease- Security Deposit & Advance Rent</li>
                            <li>Cash Exposure For Sales and Working Capital</li>
                            <li>Business Registration (Permits & Licenses)</li>
                            <li>Insurance</li>
                            <li>Motorcycle</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        TURNKEY PACKAGE
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div className="accordion-body">
                        <h4 className="fr3rd-subtitle">PHP 448k (VAT Inclusive)</h4>
                        <ul>
                            <li>Leasehold improvement (excludes renovations such as: floor tiles, glass doors/ windows, ceilings, electrical works and the likes)</li>
                            <li>Complete set-up of furniture’s, fixtures and equipment</li>
                            <li>Sales and Collection System</li>
                            <li>Initial office supplies</li>
                            <li>Signage</li>
                            <li>Staff uniform</li>
                            <li>Opening budget</li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                    <button className="accordion-button collapsed fr3rd-title" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                        TRAINING EXPENSES
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                    <div className="accordion-body">
                        <h4 className="fr3rd-subtitle">PHP 448k (VAT Inclusive)</h4>
                        <h5 className="fr3rd-sub">Initial Batch Of Nine (9) Trainees</h5>
                        <ul>
                            <li>Training Program</li>
                            <li>Trainees Allowances</li>
                            <li>Bus Transportation (Legazpi To Manila Only)</li>
                            <li>Lodging</li>
                            <li>Meals</li>
                            <li>Snacks And Lunch (During Classroom Training)</li>
                            <li>Dinner</li>
                            <li>Graduation Budget</li>
                            <li>City Tour</li> 
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Franchise3rd