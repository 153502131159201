import Alfonso from '../img/alfonso.jpg';
import Antipolo from '../img/antipolo.jpg';
import Apalit from '../img/apalit.jpg';
import Bacolod from '../img/bacolod.jpg';
import Bataan from '../img/bataan.jpg';
import Baliuag from '../img/baliuag.jpg';
import Bicutan from '../img/bicutan-map.jpg';
import Binangonan from '../img/binangonan.jpg';
import Bogo from '../img/bogo.jpg';
import Cainta from '../img/cainta.jpg';
import Caloocan3 from '../img/caloocan-3.jpg';
import Carcar from '../img/carcar.jpg';
import Cauayan from '../img/cauayan.jpg';
import CebuNorth from '../img/cebu-north.jpg';
import CebuSouth from '../img/cebu-south.jpg';
import Danao from '../img/danao.jpg';
import Dasmarinas from '../img/dasmarinas.jpg';
import Daraga from '../img/daraga.jpg';
import Dumaguete from '../img/dumaguete.jpg';
import G_araneta from '../img/g-araneta.jpg';
import Ilagan from '../img/ilagan.jpg';
import Iloilo from '../img/iloilo.jpg';
import Isabela from '../img/isabela.jpg';
import LapuLapu from '../img/lapu-lapu.jpg';
import Legazpi from '../img/legazpi.jpg';
import Lipa from '../img/lipa.jpg';
import Manila1 from '../img/manila-1-map.jpg';
import Manila2 from '../img/manila-2-map.jpg';
import Malabon from '../img/malabon.jpg';
import Malolos from '../img/malolos.jpg';
import Mandaluyong from '../img/mandaluyong.jpg';
import Mandaue from '../img/mandaue.jpg';
import Marikina from '../img/marikina-banner.jpg';
import Meycauayan from '../img/meycauayan.jpg';
import Muntinlupa from '../img/muntinlupa-map.jpg';
import Nabua from '../img/nabua.jpg';
import Naga from '../img/naga.jpg';
import Pasay from '../img/pasay.jpg';
import Polangui from '../img/polangui.jpg';
import SanJose from '../img/san-jose.jpg';
import SanMaria from '../img/santa-maria.jpg';
import SanMateo from '../img/san-mateo.jpg';
import SantaRosa from '../img/santa-rosa.jpg';
import Silang from '../img/silang.jpg';
import Tagbilaran from '../img/tagbilaran.jpg';
import TandangSora from '../img/tandang-sora.jpg';
import Toledo from '../img/toledo.jpg';
import Tubigon from '../img/tubigon.jpg';
import Valenzuela from '../img/valenzuela.jpg';

//antipolo gallery
import Ant1 from '../img/antimod/ant1.jpg';
import Ant2 from '../img/antimod/ant2.jpg';
import Ant3 from '../img/antimod/ant3.jpg';
import Ant4 from '../img/antimod/ant4.jpg';
import Ant5 from '../img/antimod/ant5.jpg';
import Ant6 from '../img/antimod/ant6.jpg';
import Ant7 from '../img/antimod/ant7.jpg';

//bataan gallery 
import Bat1 from '../img/batmod/bat1.jpg';
import Bat2 from '../img/batmod/bat2.jpg';
import Bat3 from '../img/batmod/bat3.jpg';
import Bat4 from '../img/batmod/bat4.jpg';
import Bat5 from '../img/batmod/bat5.jpg';

//cainta gallery
import Cain2 from '../img/cainmod/cain2.jpg';
import Cain1 from '../img/cainmod/cain1.jpg';
import Cain3 from '../img/cainmod/cain3.jpg';
import Cain4 from '../img/cainmod/cain4.jpg';
import Cain5 from '../img/cainmod/cain5.jpg';
import Cain6 from '../img/cainmod/cain6.jpg';
import Cain7 from '../img/cainmod/cain7.jpg';
import Cain8 from '../img/cainmod/cain8.jpg';
import Cain9 from '../img/cainmod/cain9.jpg';
import Cain10 from '../img/cainmod/cain10.jpg';
import Cain11 from '../img/cainmod/cain11.jpg';
import Cain12 from '../img/cainmod/cain12.jpg';
import Cain13 from '../img/cainmod/cain13.jpg';
import Cain14 from '../img/cainmod/cain14.jpg';

//Santa Maria gallery
import SanMar1 from '../img/sanmarmod/sanmar1.jpg';
import SanMar2 from '../img/sanmarmod/sanmar2.jpg';
import SanMar3 from '../img/sanmarmod/sanmar3.jpg';
import SanMar4 from '../img/sanmarmod/sanmar4.jpg';
import SanMar5 from '../img/sanmarmod/sanmar5.jpg';
import SanMar6 from '../img/sanmarmod/sanmar6.jpg';
import SanMar7 from '../img/sanmarmod/sanmar7.jpg';
import SanMar8 from '../img/sanmarmod/sanmar8.jpg';
import SanMar9 from '../img/sanmarmod/sanmar9.jpg';
import SanMar10 from '../img/sanmarmod/sanmar10.jpg';
import SanMar11 from '../img/sanmarmod/sanmar11.jpg';
import SanMar12 from '../img/sanmarmod/sanmar12.jpg';
import SanMar13 from '../img/sanmarmod/sanmar13.jpg';
import SanMar14 from '../img/sanmarmod/sanmar14.jpg';
import SanMar15 from '../img/sanmarmod/sanmar15.jpg';
import SanMar16 from '../img/sanmarmod/sanmar16.jpg';
import SanMar17 from '../img/sanmarmod/sanmar17.jpg';
import SanMar18 from '../img/sanmarmod/sanmar18.jpg';
import SanMar19 from '../img/sanmarmod/sanmar19.jpg';
import SanMar20 from '../img/sanmarmod/sanmar20.jpg';
import SanMar21 from '../img/sanmarmod/sanmar21.jpg';
import SanMar22 from '../img/sanmarmod/sanmar22.jpg';
import SanMar23 from '../img/sanmarmod/sanmar23.jpg';

// San Mateo gallery
import SanMat1 from '../img/sanmatmod/sanmat1.jpg';
import SanMat2 from '../img/sanmatmod/sanmat2.jpg';
import SanMat3 from '../img/sanmatmod/sanmat3.jpg';
import SanMat4 from '../img/sanmatmod/sanmat4.jpg';
import SanMat5 from '../img/sanmatmod/sanmat5.jpg';
import SanMat6 from '../img/sanmatmod/sanmat6.jpg';
import SanMat7 from '../img/sanmatmod/sanmat7.jpg';
import SanMat8 from '../img/sanmatmod/sanmat8.jpg';
import SanMat9 from '../img/sanmatmod/sanmat9.jpg';
import SanMat10 from '../img/sanmatmod/sanmat10.jpg';
import SanMat11 from '../img/sanmatmod/sanmat11.jpg';
import SanMat12 from '../img/sanmatmod/sanmat12.jpg';
import SanMat13 from '../img/sanmatmod/sanmat13.jpg';
import SanMat14 from '../img/sanmatmod/sanmat14.jpg';
import SanMat15 from '../img/sanmatmod/sanmat15.jpg';
import SanMat16 from '../img/sanmatmod/sanmat16.jpg';
import SanMat17 from '../img/sanmatmod/sanmat17.jpg';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        branch_name: 'Alfonso, Cavite',
        link: '/alfonso',
        image: Alfonso,
        address: '2nd Flr. No.4, Mabini St., Poblacion IV, Alfonso Cavite',
        embed_map: 'https://maps.google.com/maps?q=14.121213318500057,%20120.86253452976516&t=&z=15&ie=UTF8&iwloc=&output=embed',
        listImages: [Alfonso],
        contact_no: ['0915-975-9997(Globe/TM)', '0936-776-2061(Globe/TM)'],
        email: ['catesaruta@gmail.com']
    },
    {
        id: 2,
        branch_name: 'Antipolo',
        link: '/antipolo',
        image: Antipolo,
        address: '2F Unit 13 159 MLQ Plaza, 159 ML Quezon St., San Roque. Antipolo City (Landmark: Solapco Optical)',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7722.480155633672!2d121.171358122587!3d14.585391292359963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2139fd4f6382bb26!2sLoremar+Realty+Corporation!5e0!3m2!1sen!2sph!4v1559726726388!5m2!1sen!2sph',
        listImages: [Antipolo, Ant1, Ant2, Ant3, Ant4, Ant5, Ant6, Ant7],
        contact_no: ['0926-735-1873(Globe/TM)'],
        email: ['hr.ltgcreditlineantipolo@gmail.com']
    },
    {
        id: 3,
        branch_name: 'Apalit, Pampanga',
        link: '/apalit',
        image: Apalit,
        address: '3rd floor St. Jude Building Apalit Pampanga',
        embed_map: 'https://maps.google.com/maps?q=14.950224214819032,%20120.75863640473877&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Apalit],
        contact_no: ['0961-664-4947(Smart)', '0946-105-8051(TNT)' ,'0955-317-6475(Globe/TM)'],
        email: ['edwinesteller3@gmail.com']
    },
    {
        id: 4,
        branch_name: 'Bacolod',
        link: '/bacolod',
        image: Bacolod,
        address: 'Room 26 VSB Building Lacson 6th and 7th Avenue St. Bacolod City',
        embed_map: 'https://maps.google.com/maps?q=10.67587816679481,%20122.95363690783935&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Bacolod],
        contact_no: ['0935-444-5962(Globe/TM)', '0909-976-0177(Smart)'],
        email: ['carollodarphy@gmail.com']
    },
    {
        id: 5,
        branch_name: 'Bataan',
        link: '/bataan',
        image: Bataan,
        address: '2nd Flr. Brick Corner Bldg. Paterno St., Poblacion City Of Balanga, Bataan 2100',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.6422997512736!2d120.54073441436995!3d14.676229879154535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33964008fc64dfff%3A0x20566539dd06631!2sSt.+Joseph+Pre-School+of+Balanga!5e0!3m2!1sen!2sph!4v1559726807487!5m2!1sen!2sph',
        listImages: [Bataan, Bat1, Bat2, Bat3, Bat4, Bat5],
        contact_no: ['0929-147-6049(Smart)'],
        email: ['michellnicodemus.jmcbataan@gmail.com']
    },
    {
        id: 6,
        branch_name: 'Baliuag, Bulacan',
        link: '/baliuag',
        image: Baliuag,
        address: '3rd Flr. Plaza Naning F. Vergel De Dios, Brgy. Poblacion, Baliuag Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.952133709752925,%20120.89663387775275&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Baliuag],
        contact_no: ['0975-425-8155(Globe/TM)'],
        email: ['maecruz2017@gmail.com']
    },
    {
        id: 7,
        branch_name: 'Bicutan',
        link: '/bicutan',
        image: Bicutan,
        address: '#41 Orofino Bldg. Doña Soledad Ave., Better Living Subd., Parañaque City 1711',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.980096445684!2d121.02599474989418!3d14.485832089826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cefba4cfa31f%3A0xc1a496c530b978f0!2sDo%C3%B1a%20Soledad%20Ave%2C%20Para%C3%B1aque%2C%201709%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1648186076778!5m2!1sen!2sph',
        listImages: [Bicutan],
        contact_no: ['0975-130-2592(Globe)'],
        email: ['mjklendingcorp@yahoo.com', 'mjklendingcorp01@gmail.com']
    },
    {
        id: 8,
        branch_name: 'Binangonan, Rizal',
        link: '/binangonan',
        image: Binangonan,
        address: '858-A National Rd., Brgy. Calumpang Binangonan, Rizal',
        embed_map: 'https://maps.google.com/maps?q=14.53037720414258,%20121.22850637722097&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Binangonan],
        contact_no: ['0920-200-2012(Smart)', '0968-2189-410(Smart)'],
        email: ['ltgcreditlinebinangonan001@gmail.com', 'santosmariaivypoche@gmail.com']
    },
    {
        id: 9,
        branch_name: 'Bogo, Cebu',
        link: '/bogo',
        image: Bogo,
        address: 'Door 1 BGC Bldg. V, Goopio St., Brgy. Lourdes Bogo City, Cebu',
        embed_map: 'https://maps.google.com/maps?q=11.047680858486299,%20124.0033112274044&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Bogo],
        contact_no: ['0963-211-4609(Smart)', '0945-295-8444(Globe)' ,'0963-485-4727(Smart/TNT)'],
        email: ['dhinalyncatana@gmail.com']
    },
    {
        id: 10,
        branch_name: 'Cainta',
        link: '/cainta',
        image: Cainta,
        address: '2nd Flr. ADL Bldg., 688 A Bonifacio Ave., Brgy. San Juan Cainta, Rizal ',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1930.7033072821946!2d121.1214388!3d14.5758913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c734866ebda5%3A0x9891293e45b64c1e!2sLT%26G%20Credit%20Line%2C%20Cainta%20Branch!5e0!3m2!1sen!2sph!4v1636421077120!5m2!1sen!2sph',
        listImages: [Cainta, Cain1, Cain2, Cain3, Cain4, Cain5, Cain6, Cain7, Cain8, Cain9, Cain10, Cain11, Cain12, Cain13, Cain14],
        contact_no: ['0961-325-2338(Smart)', '0956-472-2421(Globe)'],
        email: ['bc.ltgcreditlinecainta@gmail.com', 'ccs.ltgcreditlinecainta@gmail.com', 'hr.ltgcreditlineantipolo@gmail.com']
    },
    {
        id: 11,
        branch_name: 'Caloocan 3',
        link: '/caloocan3',
        image: Caloocan3,
        address: '2nd Flr. RM 1, Triple J Spot Bldg. 1634 Ilang-Ilang St., Camarin, Caloocan City',
        embed_map: 'https://maps.google.com/maps?q=Brgy%20174%20Camarin,%20Caloocan%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Caloocan3],
        contact_no: ['0917-174-1970(Globe/TM)'],
        email: ['ltgcreditline.nc3@gmail.com', 'jbploanyourblessings@outlook.com', 'alicejbpinc@gmail.com']
    },
    {
        id: 12,
        branch_name: 'Car Car, Cebu',
        link: '/carcar',
        image: Carcar,
        address: 'P. Nellas St., Pob. III, Carcar City Cebu',
        embed_map: 'https://maps.google.com/maps?q=10.109154332527128,%20123.64985031207807&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Carcar],
        contact_no: ['0927-038-7208(Globe)', '0963-902-5764(Smart/TNT)'],
        email: ['snookybctoledo@gmail.com']
    },
    {
        id: 13,
        branch_name: 'Cauayan, Isabela',
        link: '/cauayan',
        image: Cauayan,
        address: 'Unit G, 2nd Flr. Paramount Square Real Estate Bldg. Don Laureano Lucas St., Corner Gov. F.N. Dy Ave., San Fermin, City Of Cauayan, Isabela',
        embed_map: 'https://maps.google.com/maps?q=16.916008041758477,%20121.78632291002152&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Cauayan],
        contact_no: '',
        email: ['rmaghanoy.ltg@gmail.com', 'rmaghanoyltg@gmail.com']
    },
    {
        id: 14,
        branch_name: 'Cebu North',
        link: '/cebunorth',
        image: CebuNorth,
        address: '2nd Flr. G & A Arcade Bldg. Wilson St., Apas Lahug, Cebu City',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.0829849084475!2d123.90097893953174!3d10.335243978309945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a999696dff7b7f%3A0xcae36ee44a169476!2sLT%26G%20Credit%20Line%20Cebu%20North%20%26%20South%20Branch!5e0!3m2!1sen!2sph!4v1648179163028!5m2!1sen!2sph',
        listImages: [CebuNorth],
        contact_no: ['0915-584-7022(Globe)', '0963-278-3855(Smart/TNT)'],
        email: ['ginasespene06@gmail.com']
    },
    {
        id: 15,
        branch_name: 'Cebu South',
        link: '/cebusouth',
        image: CebuSouth,
        address: '2nd Flr. Punta 110 Bldg. F Llmas St., Punta Princesa, Labangon Cebu City',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3925.0829849084475!2d123.90097893953174!3d10.335243978309945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a999696dff7b7f%3A0xcae36ee44a169476!2sLT%26G%20Credit%20Line%20Cebu%20North%20%26%20South%20Branch!5e0!3m2!1sen!2sph!4v1648179163028!5m2!1sen!2sph',
        listImages: [CebuSouth],
        contact_no: ['0915-584-7022(Globe)', '0951-353-1540(Smart)'],
        email: ['ginasespene06@gmail.com']
    },
    {
        id: 16,
        branch_name: 'Danao, Cebu',
        link: '/danao',
        image: Danao,
        address: '2nd Flr. Tokyo Bldg. Catadman, Sabang, Danao City, Cebu',
        embed_map: 'https://maps.google.com/maps?q=10.49936973537122,%20124.0309502022266&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Danao],
        contact_no: ['0927-386-0140(Globe)'],
        email: ['pononisheil@gmail.com']
    },
    {
        id: 17,
        branch_name: 'Dasmariñas, Cavite',
        link: '/dasmariñas',
        image: Dasmarinas,
        address: 'JVB Bldg. #3 Don Placido Campos Ave., San Agustin 3, Dasmariñas City, Cavite',
        embed_map: 'https://maps.google.com/maps?q=14.326963673601076,%20120.93521737389156&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Dasmarinas],
        contact_no: ['0970-937-3870(Smart)'],
        email: ['jeorgiereyniadelmendo@gmail.com', 'joseph_barra@yahoo.com']
    },
    {
        id: 18,
        branch_name: 'Daraga',
        link: '/daraga',
        image: Daraga,
        address: '2F JAR Bldg., Bañag, Daraga, Albay',
        embed_map: 'https://maps.google.com/maps?q=13.154161369356771,%20123.71129261259618&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Daraga],
        contact_no: ['0955-397-6367(Globe/TM)'],
        email: ['']
    },
    {
        id: 19,
        branch_name: 'Dumaguete',
        link: '/dumaguete',
        image: Dumaguete,
        address: 'B3 Bldg. Northroad Daro, Dumaguete City, 6200',
        embed_map: 'https://maps.google.com/maps?q=9.317950641096154,%20123.30136098909685&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Dumaguete],
        contact_no: ['0946-305-7554(TNT)', '0931-826-0655(Sun)'],
        email: ['reinsky_ems07@gmail.com']
    },
    {
        id: 20,
        branch_name: 'G. Araneta, QC',
        link: '/g-araneta',
        image: G_araneta,
        address: '305 Pasda Bldg. 9P Florentino St., Cor. G. Araneta Ave., Brgy. Sto. Domingo, Quezon City',
        embed_map: 'https://maps.google.com/maps?q=14.629667848181024,%20121.01184429336334&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [G_araneta],
        contact_no: ['0916-617-9259(Globe)'],
        email: ['sharky.santos@gmail.com', 'ltgcredit.onesm01@gmail.com', 'onesm.paolobobier@gmail.com']
    },
    {
        id: 21,
        branch_name: 'Ilagan, Isabela',
        link: '/ilagan',
        image: Ilagan,
        address: 'Bldg. B, Unit 13, Triton Strip Mall, Alibagu Ilagan City, Isabela',
        embed_map: 'https://maps.google.com/maps?q=17.0967251339501,%20121.85542162601995&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Ilagan],
        contact_no: ['0945-147-0166(Globe)', '0966-347-9270(Globe/TM)'],
        email: ['jlabradoor.ltg@gmail.com', 'joybrtlm@gmail.com']
    },
    {
        id: 22,
        branch_name: 'Iloilo City',
        link: '/iloilo',
        image: Iloilo,
        address: 'Col. Ildefonso G Lauz Bldg. Brgy. Buhang Taft North, Mandurriao, Iloilo City',
        embed_map: 'https://maps.google.com/maps?q=10.717751202697267,%20122.54854398342569&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Iloilo],
        contact_no: ['0966-480-0060(Globe/TM)', '0946-985-7788(TNT)'],
        email: ['ltgiloilobranch@gmail.com', 'mignonette0130@gmail.com']
    },
    {
        id: 23,
        branch_name: 'Isabela',
        link: '/isabela',
        image: Isabela,
        address: 'Unit G, 2/F Paramount Square Real Estate Bldg., Don Laureano Lucas St. Corner Gov. F. N. Dy Ave., San Fermin, Cauayan City, ISABELA, 3305',
        embed_map: 'https://maps.google.com/maps?q=San%20Fermin,%20Cauayan%20City,%20ISABELA,%203305&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Isabela],
        contact_no: ['0997-784-6613(Globe/TM)'],
        email: ['']
    },
    {
        id: 24,
        branch_name: 'Lapu Lapu, Cebu',
        link: '/lapu-lapu',
        image: LapuLapu,
        address: 'Pajo Terminal, Lapu Lapu City, Cebu',
        embed_map: 'https://maps.google.com/maps?q=10.28592554966794,%20123.96707420107037&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [LapuLapu],
        contact_no: ['0951-353-5266(Smart)', '0963-808-9824(Smart/TNT)'],
        email: ['mcasumpang219@gmail.com']
    },
    {
        id: 25,
        branch_name: 'Legazpi',
        link: '/legazpi',
        image: Legazpi,
        address: '2nd floor, Unit C, CQ Bldg. 3 Phase 2, Rosal Street, Imperial Court, Brgy. 24, Rizal, Legazpi City',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.83043504317686!2d123.75241813738648!3d13.14429053493856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a1033328711cbd%3A0x9bc11ac59ca83bb1!2sCQ%20Bldg%203!5e0!3m2!1sen!2sph!4v1654047357304!5m2!1sen!2sph',
        listImages: [Legazpi],
        contact_no: ['0995-104-6267(Globe)', '0960-867-0566(Smart)', '0917-531-3862(Globe/TM)'],
        email: ['franchise@ltgcreditline.com', 'franchise@mamsltg.com']
    },
    {
        id: 26,
        branch_name: 'Lipa, Batangas',
        link: '/lipa',
        image: Lipa, 
        address: '0770-8 Pearl Bldg. And Residences, Purok 2, Brgy. Marauoy Lipa City',
        embed_map: 'https://maps.google.com/maps?q=13.967833919648191,%20121.1660795495648&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Lipa],
        contact_no: ['0936-343-2188(Globe/TM)'],
        email: ['kieshatamis@gmail.com']
    },
    {
        id: 27,
        branch_name: 'Manila 1',
        link: '/manila1',
        image: Manila1,
        address: '3rd Flr. Room 305 DMV Bldg. Herbosa St. Tondo, Manila',
        embed_map: 'https://maps.google.com/maps?q=14.618515734298182,%20120.96448760288433&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Manila1],
        contact_no: ['0910-539-5384(TNT)', "0907-815-1615(TNT)"],
        email: ['lornafampulme@yahoo.com']
    },
    {
        id: 28,
        branch_name: 'Manila 2 Pureza',
        link: '/manila2',
        image: Manila2,
        address: 'The Station Point Bldg. 3299 R. Magsaysay Blvd. Corner Maganda St. Sampaloc Manila',
        embed_map: 'https://maps.google.com/maps?q=14.601900566744707,%20121.00495676795397&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Manila2],
        contact_no: ['0910-539-5384(TNT)'],
        email: ['lornafampulme@yahoo.com']
    },
    {
        id: 29,
        branch_name: 'Malabon',
        link: '/malabon',
        image: Malabon,
        address: '3F, Symaco Bldg, 678 Rizal Ave. Ext,Brgy. Tanong, Malabon City',
        embed_map: 'https://maps.google.com/maps?q=678%20Rizal%20Ave.%20Ext,Brgy.%20Tanong,%20Malabon%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Malabon],
        contact_no: ['0906-872-9470(Globe/TM)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    },
    {
        id: 30,
        branch_name: 'Malolos, Bulacan',
        link: '/malolos',
        image: Malolos,
        address: 'RP Mendoza & Sons Bldg. A Mabini St., Pinagbakahan Malolos, Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.868160328826246,%20120.82117632960129&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Malolos],
        contact_no: ['0955-438-7057(Globe/TM)'],
        email: ['rowenaamurao@yahoo.com', 'echavarriairis@gmail.com']
    },
    {
        id: 31,
        branch_name: 'Mandaluyong',
        link: '/mandaluyong',
        image: Mandaluyong,
        address: '315 Unit 2, 2/F Julio Antonio Bldg., Maysilo Circle, Plainview Mandaluyong City',
        embed_map: 'https://maps.google.com/maps?q=Maysilo%20Circle,Plainview%20Mandaluyong%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Mandaluyong],
        contact_no: ['0947-329-3789(Smart)'],
        email: ['']
    },
    {
        id: 32,
        branch_name: 'Mandaue, Cebu',
        link: '/mandaue',
        image: Mandaue,
        address: 'RM 209, 2nd Flr, Grand Arcade Bldg. A.C., Cortes Ave., Mandaue City',
        embed_map: 'https://maps.google.com/maps?q=10.330555385317457,%20123.94729727611853&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Mandaue],
        contact_no: ['0915-862-8317(Globe/TM)'],
        email: ['luchiesenoglinogo@gmail.com']
    },
    {
        id: 33,
        branch_name: 'Marikina',
        link: '/marikina',
        image: Marikina,
        address: '2nd Flr. Castillo Bldg. 50 General Ordoñez Ave., Brgy. Concepcion Uno, Marikina City',
        embed_map: 'https://maps.google.com/maps?q=14.64614380742703,%20121.11265601517296&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Marikina],
        contact_no: ['0999-990-6949(Smart)'],
        email: ['jbploanyourblessings@outlook.com', 'sherylramosltg@gmail.com', 'marikinaltgcreditline@gmail.com']
    },
    {
        id: 34,
        branch_name: 'Meycauayan, Bulacan',
        link: '/meycauayan',
        image: Meycauayan,
        address: '3rd Flr. Aliw Complex Bldg. Mc Arthur Calvario, Meycauayan Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.737705886261061,%20120.9604839624881&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Meycauayan],
        contact_no: ['0932-965-3121(Sun)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    },
    {
        id: 35,
        branch_name: 'Muntinlupa',
        link: '/muntinlupa',
        image: Muntinlupa,
        address: 'Unit 2, 2nd Flr. MSE Bldg. III Katihan St., Poblacion, Muntinlupa City',
        embed_map: 'https://maps.google.com/maps?q=14.388753382041653,%20121.0425516409651&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Muntinlupa],
        contact_no: ['0936-243-1979(Globe/TM)'],
        email: ['jeng.alhambra@gmail.com']
    },
    {
        id: 36,
        branch_name: 'Nabua',
        link: '/nabua',
        image: Nabua,
        address: '2nd Flr. 5 Peyra Bldg., San Francisco, Nabua Camarines Sur',
        embed_map: 'https://maps.google.com/maps?q=13.411171401296697,%20123.37951424294387&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Nabua],
        contact_no: ['0936-323-4823(Globe/TM)', '0951-456-5631(Smart)'],
        email: ['ltgnabua.02@gmail.com', 'dabegailfatima@gmail.com']
    },
    {
        id: 37,
        branch_name: 'Naga',
        link: '/naga',
        image: Naga,
        address: '2nd Flr. Unit 10 Zone 5 MMCN Bldg., Panganiban Drive Concepcion Pequeña, Naga City',
        embed_map: 'https://maps.google.com/maps?q=13.62178897153642,%20123.19738677163974&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Naga],
        contact_no: ['0936-323-4823(Globe/TM)','0938-554-5877(TNT)'],
        email: ['kheavalenzuela20@gmail.com', 'joseph_barra@yahoo.com']
    },
    {
        id: 38,
        branch_name: 'Pasay',
        link: '/pasay',
        image: Pasay,
        address: '202 CTC Allied Resources Bldg.,No. 15 Don Benito Hernandez Street, Brgy. 76 Zone 10, Pasay City',
        embed_map: 'https://maps.google.com/maps?q=Don%20Benito%20Hernandez%20,%20Pasay%20City.&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Pasay],
        contact_no: ['0916-654-3314(Globe/TM)'],
        email: ['ltgpasay@gmail.com']
    },
    {
        id: 39,
        branch_name: 'Polangui',
        link: '/polangui',
        image: Polangui,
        address: 'Samar Bldg,.Brgy. Gabon, Polangui, Albay',
        embed_map: 'https://maps.google.com/maps?q=Samar%20Bldg,.Brgy.%20Gabon,Polangui,%20Albay&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Polangui],
        contact_no: ['0975-981-3400(Globe/TM)'],
        email: ['']
    },
    {
        id: 40,
        branch_name: 'San Jose, Mindoro',
        link: '/san-jose',
        image: SanJose,
        address: '0387 Capt. Cooper St., Brgy. 4 San Jose, Occidental Mindoro',
        embed_map: 'https://maps.google.com/maps?q=12.352602752139648,%20121.06159729306083&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [SanJose],
        contact_no: ['0945-377-9428(Globe/TM)', '0905-396-4298(Globe/TM)'],
        email: ['khristinemodena.ltgsjm@gmail.com']
    },
    {
        id: 41,
        branch_name: 'Santa Maria',
        link: '/santa-maria',
        image: SanMaria,
        address: 'Sitio, Malawak, Brgy. Guyong Sta. Maria Bulacan',
        embed_map: 'https://maps.google.com/maps?q=14.836418841041583,%20120.97666576495854&t=&z=19&ie=UTF8&iwloc=&output=embed',
        listImages: [SanMaria, SanMar1, SanMar2, SanMar3, SanMar4, SanMar5, SanMar6, SanMar7, SanMar8, SanMar9, SanMar10, SanMar11, SanMar12, SanMar13, SanMar14, SanMar15, SanMar16, SanMar17, SanMar18, SanMar19, SanMar20, SanMar21, SanMar22, SanMar23],
        contact_no: ['0939-949-9958(Globe)'],
        email: ['escatleb@gmail.com']
    },
    {
        id: 42,
        branch_name: 'San Mateo',
        link: '/san-mateo',
        image: SanMateo,
        address: '2F Unit 4, JET Adona Bldg., 130 Gen. Luna St. Brgy. Guitnang Bayan 2, San Mateo Rizal',
        embed_map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d964.8137794726042!2d121.12172732915533!3d14.698159696577338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397bb7b064e8c0f%3A0x9c067106812e64d2!2sLT%26G+Credit+Line%2C+San+Mateo+Branch!5e0!3m2!1sen!2sph!4v1560581251943!5m2!1sen!2sph',
        listImages: [SanMateo, SanMat1, SanMat2, SanMat3, SanMat4, SanMat5, SanMat6, SanMat7, SanMat8, SanMat9, SanMat10, SanMat11, SanMat12, SanMat13, SanMat14, SanMat15, SanMat16, SanMat17],
        contact_no: ['241-2183', '0995-6457168(Globe)', '0961-1577418(Smart)'],
        email: ['ccs.ltgsanmateo@gmail.com']
    },
    {
        id: 43,
        branch_name: 'Santa Rosa',
        link: '/santa-rosa',
        image: SantaRosa,
        address: 'Lot 253-A-6 Rosal Street, L&M Subdivision,Brgy. Market Area, Sta. Rosa Laguna',
        embed_map: 'https://maps.google.com/maps?q=Market%20Area,%20Sta.%20Rosa%20Laguna&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [SantaRosa],
        contact_no: ['0956-158-5075(Globe/TM)'],
        email: ['rpax.adm@gmail.com', 'rpaxcorp@gmail.com']
    },
    {
        id: 44,
        branch_name: 'Silang, Cavite',
        link: '/silang',
        image: Silang,
        address: 'Unit 2B Phase 5B BLK47 Lot 15, Brgy. Narra III, Silang Cavite',
        embed_map: 'https://maps.google.com/maps?q=14.27631977525499,%20120.99349645315209&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Silang],
        contact_no: ['0925-761-2038(Sun)'],
        email: ['mejia.aloha@gmail.com', 'mjperodes10@gmail.com']
    },
    {
        id: 45,
        branch_name: 'Tagbilaran, Cebu',
        link: '/tagbilaran',
        image: Tagbilaran,
        address: 'Bohol Chronicle Bldg. 94 B. Inting St., Pob. II, Tagbilaran City, Bohol',
        embed_map: 'https://maps.google.com/maps?q=9.644703547442893,%20123.85856777953848&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Tagbilaran],
        contact_no: ['0995-471-9323(Globe)', '0910-441-6897(TNT)'],
        email: ['Christine.ibaoc@gmail.com']
    },
    {
        id: 46,
        branch_name: 'Tandang Sora',
        link: '/tandang-sora',
        image: TandangSora,
        address: 'Unit 503, LRI Bldg., 21 Congressional Ave., Brgy. Bahay Toro, Quezon City',
        embed_map: 'https://maps.google.com/maps?q=21%20Congressional%20Ave.,%20Brgy.%20Bahay%20Toro,%20Quezon%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [TandangSora],
        contact_no: ['0949-881-8911(Smart)'],
        email: ['ltgcreditline.tandangsora@gmail.com', 'jennethareglo@gmail.com', 'jbploanyourblessings@outlook.com']
    },
    {
        id: 47,
        branch_name: 'Toledo, Cebu',
        link: '/toledo',
        image: Toledo,
        address: 'Xon Shen Bldg., Brgy. Sangi, Toledo City, Cebu',
        embed_map: 'https://maps.google.com/maps?q=10.38602223035746,%20123.6496607983151&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Toledo],
        contact_no: ['0927-038-7208(Globe/TM)', '0963-001-8803(Smart/TNT)'],
        email: ['snookybctoledo@gmail.com']
    },
    {
        id: 48,
        branch_name: 'Tubigon, Cebu',
        link: '/tubigon',
        image: Tubigon,
        address: 'C&E Yu Bldg., 206 Maximo Lasco St., Centro Tubigon, Bohol',
        embed_map: 'https://maps.google.com/maps?q=9.9491959826641,%20123.96051978933511&t=&z=17&ie=UTF8&iwloc=&output=embed',
        listImages: [Tubigon],
        contact_no: ['0946-284-4734(TNT)', '0938-999-7003(TNT)'],
        email: ['metzijeand@gmail.com']
    },
    {
        id: 49,
        branch_name: 'Valenzuela',
        link: '/valenzuela',
        image: Valenzuela,
        address: 'Room 5, 2F Rivera Bldg.,8 Maysan Road, Valenzuela City',
        embed_map: 'https://maps.google.com/maps?q=2F%20Rivera%20Bldg.,8%20Maysan%20Road,%20Valenzuela%20City&t=&z=13&ie=UTF8&iwloc=&output=embed',
        listImages: [Valenzuela],
        contact_no: ['0932-263-9960(Sun)'],
        email: ['ltgcreditlinemalabon.hr@gmail.com']
    }
];